const Modernizr = require('modernizr');

/**
 * Check if this browser supports ProPoint.
 * Remember, this code has to be fairly old-fashioned, as anything too modern will defeat its purpose!
 * Requires Modernizr to be made available prior to execution.
 *
 * Modernizr has both synchronous and asynchronous tests which need to be handled differently
 *
 * @var {Modernizr} Modernizr
 */

const syncTests = [
    'arrow',
    'promises',
];

const asyncTests = [
    // 'indexeddb'
];

/**
 * @param {string} feature The name of the feature to test for. Note: You will need the non-namespaced version of what is
 * defined in modernizr-config.json. Eg. "es6/arrow" becomes "arrow".
 */
function checkSyncTest(feature) {
    if (Modernizr.hasOwnProperty(feature)) {
        // Unfortunately, the Modernizr global object can contain a mixture of boolean primitives and Boolean objects
        if (
            (typeof Modernizr[feature] === 'boolean' && Modernizr[feature] === true)
            || (Modernizr[feature] instanceof Boolean && Modernizr[feature].valueOf() === true)
        ) {
            return true;
        }
    }

    return false;
}

function showUnsupportedMessage() {
    // Empty the entire body so that our application cannot start up properly, and replace with our message
    document.body.innerHTML = '<div id="unsupported-browser-message" class="alert alert-danger" role="alert">'
        + '    <h4><i class="glyphicon glyphicon-warning-sign"></i> '
        + '    You appear to be running an outdated web browser which is incompatible with ProPoint</h4>'
        + '    <p>Please update your browser before continuing. ProPoint requires one of the following:</p>'
        + '    <ul>'
        + '        <li><strong>Windows</strong>: latest version of Chrome '
        + '            <strong><em>(Not running in Incognito Mode)</em></strong>'
        + '        </li>'
        + '        <li><strong>Windows</strong>: latest version of Firefox '
        + '            <strong><em>(Not running in Private Mode and with Remember history enabled)</em></strong>'
        + '        </li>'
        + '        <li>'
        + '            <!-- Note: We have a 5-year support policy for iPads see Confluence for more information -->'
        + '            <strong>iPad</strong>: Safari on iOS 12.4.2 or later '
        + '            <strong><em>(Not running in Private Mode)</em></strong>'
        + '        </li>'
        + '    </ul>'
        + '</div>';
    alert('Your browser appears to be outdated. Please update before continuing.');
}

// Check any synchronous tests
syncTests.map((testName) => {
    const result = checkSyncTest(testName);
    console.log('Modernizr: ', testName + ' result: ', result);
    if (!result) {
        showUnsupportedMessage();
    }
});

let failedTests = 0;
let testsRun = 0;

// Watch for results from asynchronous tests
asyncTests.map((testName) => Modernizr.on(testName, (result) => {
    console.log('Modernizr: ', testName + ' result: ', result);
    if (!result) {
        failedTests++;
    }
    testsRun++;
    if (testsRun === asyncTests.length && failedTests > 0) {
        showUnsupportedMessage();
    }
}));
